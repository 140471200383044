<!--  -->
<template>
  <div id="Index">
    <el-dialog
      title="注册协议"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <p>
        【审慎阅读】您在申请注册流程中点击同意前，应当认真阅读以下协议。
        <span>请您务必审慎阅 读、充分理解协议中相关条款内容，其中包括：</span>
      </p>

      <div>
        <p>1、与您约定免除或限制责任的条款；</p>

        <p>2、与您约定法律适用和管辖的条款；</p>

        <p>3、与您使用本网站服务相关的其他重要条款。</p>
      </div>
      <p>
        <span>【特别提示】</span>
        您已充分理解，当您按照注册页面提示填写信息、阅读并同意协议且完成全部注册程序后，即表示您已充分阅读、理解并接受协议的全部内容，并将基于您的真实需求而使用西知网装修网的服务。阅读协议的过程中，如果您不同意相关协议或其中任何条款约定，您应立即停止注册程序。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clickNocenter">暂不同意</el-button>
        <el-button type="primary" @click="clickCenter">同意并继续</el-button>
      </span>
    </el-dialog>
    <!-- 登录框 -->
    <LoginBox ref="loginBox"></LoginBox>
    <div class="Index_top">
      <div class="nav">
        <div>
          <div v-for="item in navlist" :key="item.id">
            <el-link :href="item.href" target="_blank">{{ item.name }}</el-link>
          </div>
        </div>
      </div>
      <div class="top">
        <img
          @click="clickSendIndex"
          src="@/assets/image/logo_index.png"
          alt=""
        />
        <div>
          <el-button v-if="!loginBool" @click="clickimmediately"
            >立即登录</el-button
          >
          <el-dropdown v-else>
            <span class="el-dropdown-link">
              <img v-if="myInfo.avatar" :src="myInfo.avatar" alt="" />
              <img v-else src="@/assets/image/avatar.png" alt="" />
              <div>{{ myInfo.name }}</div>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><span @click="Wraongquestion">错题集</span></el-dropdown-item
              >
              <el-dropdown-item
                ><span @click="colltionclick">收藏夹</span></el-dropdown-item
              >
              <el-dropdown-item
                ><span @click="Specialpartice">专项练习</span></el-dropdown-item
              >
              <el-dropdown-item><span @click="RankingList">排行榜</span></el-dropdown-item>
              <el-dropdown-item
                ><span @click="clicksignOut">退出登录</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <div v-if="loginBool">
            <img v-if="myInfo.avatar" :src="myInfo.avatar" alt="" />
            <img v-else src="@/assets/image/avatar.png" alt="" />
            <div>{{ myInfo.name }}</div>
          </div>
          <el-button class="person" plain><i class="el-icon-s-check"></i> 个人中心</el-button>
          <el-button class="returnLogin" v-if="loginBool" @click="clicksignOut"
            ><i class="el-icon-arrow-left"></i>退出登录</el-button
          > -->
        </div>
      </div>
    </div>
    <div class="Index_main">
      <div class="main"><router-view></router-view></div>
    </div>
  </div>
</template>

<script>
import LoginBox from "@/components/LoginBox";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import wxlogin from "vue-wxlogin";
// import {http} from "@/utils/http.js";
import axios from "axios";
export default {
  inject: ["reload"],
  //import引入的组件需要注入到对象中才能使用
  components: {
    wxlogin,
    LoginBox,
  },

  data() {
    //这里存放数据
    return {
      myInfo: {},
      loginBool: false,
      userInfo: "",
      loginDialogBool: false, //登录
      centerDialogVisible: false, //协议弹框
      checkBool: true, //是否同意
      showBtn: true, //展示按钮
      navlist: [
        { id: 1, name: "首页", href: "https://www.spanishknow.com/" },
        { id: 2, name: "西语学习", href: "https://www.spanishknow.com/xuexi/" },
        { id: 3, name: "备考辅导", href: "https://www.spanishknow.com/kszx/" },
        {
          id: 4,
          name: "留学指南",
          href: "https://www.spanishknow.com/liuxue/",
        },
        { id: 5, name: "就业推荐", href: "https://www.spanishknow.com/jiuye/" },
        { id: 6, name: "旅游攻略", href: "https://www.spanishknow.com/lvyou/" },
        { id: 7, name: "西语新闻", href: "https://www.spanishknow.com/xytt/" },
        { id: 8, name: "娱乐", href: "https://www.spanishknow.com/yule/" },
        { id: 9, name: "问题库", href: "https://www.spanishknow.com/wenda/" },
        { id: 10, name: "资源中心", href: "https://www.spanishknow.com/zyxz/" },
      ],
      objWx: {
        appid: "",
        scope: "snsapi_login",
        redirect_uri: "http%3a%2f%2ftk.spanishknow.com%2f%23%2fwxLogin",
        state: "", //随机数
        style: "black",
        href: "",
      },
      phone: "", //手机号
      code: "", //验证码
      titlename: "",
      loginHeight: 450, //轮播图高度
      showModel: false, //刷新二维码
      username: "", //用户名
      fileList: [{ url: require("../assets/image/avatar.png") }],
      imgUrl: "", //头像
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    clickCenter() {
      // 同意
      let _this = this;
      _this.centerDialogVisible = false;
      _this.checkBool = true;
    },
    clickNocenter() {
      //不同意
      let _this = this;
      _this.centerDialogVisible = false;
      _this.checkBool = false;
    },
    getMyInfo() {
      let _this = this;
      if (_this.userInfo) {
        _this.$api.me().then((res) => {
          _this.myInfo = res.data;
        });
      }
    },
    clickSendIndex() {
      // 跳转到首页
      let _this = this;
      if (this.$route.path != "/Index/Gateway") {
        _this.$router.push("/");
      }
    },
    clickimmediately() {
      // 登录框
      let _this = this;
      // _this.loginDialogBool = true;
      // _this.weixin_login();
      _this.$refs.loginBox.clickloginDialogBool();
    },
    clicksignOut() {
      let _this = this;
      if (this.$route.path != "/Index/Gateway") {
        _this.$router.push("/");
      }
      localStorage.clear();
      this.$router.go(0);
    },
    // 错题集
    Wraongquestion() {
      this.$router.push("/Index/PersonalCenter/WrongQuestions");
    },
    //收藏夹
    colltionclick() {
      this.$router.push("/Index/PersonalCenter/colltion");
    },
    // 专项练习
    Specialpartice() {
      this.$router.push("/Index/PersonalCenter/Specialpartice");
    },
    // 排行榜
    RankingList() {
      this.$router.push("/Index/PersonalCenter/RankingList");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let _this = this;

    _this.userInfo = localStorage.userInfo;
    if (_this.userInfo) {
      _this.loginBool = true;
    } else {
      _this.loginBool = false;
    }
    _this.getMyInfo();
    _this.$store.commit("changeurl", window.location.href);
  },
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@bgcolor: #df3833;
#Index {
  width: 100%;
  height: 100%;

  .login {
    // 登录框
    /deep/ .el-dialog {
      // height: 480px;
      border-radius: 8px;
      // /deep/.el-carousel__container{
      //   height: 400px;
      // }
      .showBtn {
        height: 400px;
        .logo {
          text-align: center;
          margin-bottom: 40px;
          img {
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
          }
          div {
            font-size: 20px;
          }
        }
      }
      .avaterBox {
        .van-uploader {
          position: relative;
          z-index: 9999;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 25px;
          img {
            width: 80px;
            height: 80px;
          }
          /deep/.van-uploader__input {
            z-index: 9999;
          }
          button {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 30px;
            font-size: 12px;
            background-color: rgba(0, 0, 0, 0.5);
            bottom: -18px;
            border-radius: 0;
            color: #fff;
          }
        }
        .avatar-uploader {
          width: 80px;
          height: 80px;
          margin: 0 auto;
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          button {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 26px;
            line-height: 26px;
            font-size: 12px;
            background-color: rgba(0, 0, 0, 0.5);
            bottom: 0px;
            padding: 0;
            border-radius: 0;
            color: #fff;
            left: 0;
            margin: 0;
          }
        }

        i {
          font-size: 18px;
        }
        .msg {
          text-align: center;
          h2 {
            height: 30px;
            font-size: 22px;

            color: #333;
            line-height: 30px;
            margin: 0;
          }
          p {
            height: 22px;
            font-size: 16px;

            color: #999;
            line-height: 22px;
            margin: 6px 0 30px;
          }
        }
        .username {
          width: 100%;
          height: 44px;
          border: 1px solid #ccc;
          border-radius: 20px;
          margin: 20px 0px 0px;
          display: flex;
          box-sizing: border-box;
          > div {
            &:first-child {
              display: flex;
              align-items: center;
              margin-left: 20px;

              img {
                width: 18px;
                margin-right: 5px;
              }
            }
          }
          /deep/.el-input {
            width: 200px;
            height: 44px !important;
            border: none;
            .el-input__inner {
              border: none;
              height: 42px;
              padding-top: 2px;
            }
          }
        }
        button {
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 40px;
          width: 100%;
          margin-left: 2px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 44px;
          margin-top: 20px;
          // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
        }
        .el-button--primary.is-disabled,
        .el-button--primary.is-disabled:active,
        .el-button--primary.is-disabled:focus,
        .el-button--primary.is-disabled:hover {
          background: rgba(199, 0, 11, 0.3);
          border: rgba(199, 0, 11, 0.3);
        }
        .el-button--primary {
          background: @bgcolor;
          border: @bgcolor;
        }
      }
      .title {
        // width: 20px;
        height: 20px;
        background: url(../assets/image/left.png) 0 0 no-repeat;
        background-size: 20px 20px;
        position: absolute;
        top: -0px;
        left: 20px;
        cursor: pointer;
        padding-left: 20px;
      }

      .btn {
        margin-left: 5px;
        button {
          width: 320px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 50px;
          background: @bgcolor;
          border: @bgcolor;
          i {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-top: -3px;
            vertical-align: middle;
            background: url(../assets/image/weixin.svg);
            background-size: 30px 30px;
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }
        > div {
          &:nth-child(2) {
            button {
              color: #000;
              background: #fbe7e7;
              border: none;
            }
          }
        }
      }
      .agreement {
        padding: 0 15px 20px 15px;
        font-size: 12px;
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background: #df3833;
          border: #df3833;
        }
        input {
          vertical-align: sub;
        }
        span {
          color: #666;
          a {
            color: #df3833;
            cursor: pointer;
          }
        }
      }
      .weixinBox {
        > div {
          padding: 60px 0;
          padding-bottom: 20px;
          text-align: center;
          font-size: 18px;
        }
        /deep/ iframe {
          width: 200px;
          height: 200px;
        }
        .modeShua {
          position: absolute;
          left: 74px;
          z-index: 200;
          top: 76px;
          background: rgba(0, 0, 0, 0.5);
          width: 181px;
          height: 181px;
          padding-top: 20px;
          color: #fff;
          padding-top: 50px;
          div {
            margin-bottom: 10px;
          }
          button {
            width: 50px;
            height: 30px;
            border-radius: 5px;
            &:hover {
              color: @bgcolor;
            }
          }
        }
      }
      .phoneBox {
        text-align: center;
        .phone {
          border-radius: 40px;
          width: 320px;
          margin: 20px 0;
          height: 42px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-left: 18px;
          margin-left: 4px;
          input {
            font-size: 14px;
            box-sizing: border-box;
            color: #333;
            margin: 20px 0;
            height: 100%;
            padding-left: 10px;
            border: none;
          }
        }
        .weixin {
          cursor: pointer;
          &:hover {
            color: @bgcolor;
          }
        }

        button {
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 40px;
          width: 320px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 50px;
          // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
        }
        .el-button--primary.is-disabled,
        .el-button--primary.is-disabled:active,
        .el-button--primary.is-disabled:focus,
        .el-button--primary.is-disabled:hover {
          background: rgba(199, 0, 11, 0.3);
          border: rgba(199, 0, 11, 0.3);
        }
        .el-button--primary {
          background: @bgcolor;
          border: @bgcolor;
        }
      }
      .codeBox {
        text-align: center;
        .code_bg {
          width: 300px;
          height: 150px;
          background: url(../assets/image/code.svg) no-repeat 85px;
        }
        > div {
          span {
            color: @bgcolor;
          }
        }
        input {
          border-radius: 40px;
          width: 300px;
          margin: 20px 0;
          height: 42px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-left: 40px;
          margin-left: 15px;
          background: url(../assets/image/codeD.png) no-repeat 10px 10px;
          background-size: 20px 20px;
        }

        button {
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 40px;
          width: 300px;
          margin-left: 2px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 50px;
          // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
        }
        .el-button--primary.is-disabled,
        .el-button--primary.is-disabled:active,
        .el-button--primary.is-disabled:focus,
        .el-button--primary.is-disabled:hover {
          background: rgba(199, 0, 11, 0.3);
          border: rgba(199, 0, 11, 0.3);
        }
        .el-button--primary {
          background: @bgcolor;
          border: @bgcolor;
        }
      }
    }
  }
  .Index_top {
    width: 100%;
    height: 100px;
    background: #fff;
    box-shadow: 0 4px 6px 0 rgba(215, 220, 233, 0.5),
      0 0 7px 0 rgba(199, 206, 223, 0.1);
    font-size: 14px;
    color: #999;
    .nav {
      height: 40px;
      background: #f1f1f1;
      line-height: 40px;
      > div {
        width: 1200px;
        margin: auto;
        display: flex;
        > div {
          margin-right: 35px;
          &:first-child {
            margin-left: 5px;
          }
        }

        .el-link--default {
          &:hover {
            color: #df3833 !important;
          }
        }
        /deep/.el-link.el-link--default:after,
        .el-link.el-link--primary.is-underline:hover:after,
        .el-link.el-link--primary:after {
          border-color: #df3833;
          border: 1px solid #df3833 !important;
        }
      }
    }
    .top {
      height: 60px;
      width: 1350px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: auto;
        height: 25px;
        vertical-align: middle;
        // margin-left: 20px;
        cursor: pointer;
        border-radius: 50%;
      }
      > img {
        border-radius: 0;
        height: 45px;
      }
      // > div {
      //   display: flex;
      //   align-items: center;
      //   > div {
      //     display: flex;
      //     align-items: center;
      //     img {
      //       width: 30px;
      //       height: 30px;
      //     }
      //   }
      //   div {
      //     margin: 0 10px;
      //   }
      // }
      /deep/ button {
        // width: 8px;
        font-size: 12px;
        text-align: center;
        color: #fff;
        border-radius: 5px;
        line-height: 15px;
        background: #df3833;
        padding: 10px;
        border: none;
      }
      //   .person {
      //     background: #fff;
      //     color: #ccc;
      //   }
      // }
      .el-dropdown-link {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        div {
          margin: 0 10px;
        }
      }
    }
  }
  .Index_main {
    background: #f7f7f7;
    // height: calc(100% - 60px);
    .main {
      // height: 100%;

      margin: 0 auto;
    }
  }
}
/deep/.el-dropdown-menu__item {
  width: 100px;
  padding: 0;
  span {
    display: inline-block;
    width: 100px;
    padding: 0 20px;
  }
}
</style>